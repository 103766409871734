import React, { useState, useEffect } from 'react';

const LocalTime = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const updateTime = () => setTime(new Date());

    const timerId = setInterval(updateTime, 1000); 

    return () => clearInterval(timerId); 
  }, []);

  const formatTime = (date) => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <div>
      {formatTime(time)}
    </div>
  );
};

export default LocalTime;
