import NavContent from "../components/NavContent";
import Footer from "../components/Footer";

const About = () =>{
    return(
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflowY: 'auto' }}>
        <NavContent/>
        
        <Footer/>
        </div>
        );
        }
export default About;