import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';


const RecentWorkList = () => {

const ScrollableList = styled.div`
  max-height: 400px;
  overflow-y: auto;

      /* Hide scrollbar for WebKit browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;

  /* Hide scrollbar for Edge and Internet Explorer */
  -ms-overflow-style: none;
`;


  const items = [
    { id: 1, name: 'PlantMate an E-commerce project', description: 'Design & Interaction' },
    { id: 2, name: 'Wellness project', description: 'Design & Interaction' },
    { id: 3, name: 'TripEase: A Case Study on Integarated\n Travel Booking and Planning', description: 'Design & Interaction' },
    { id: 4, name: 'Redesign an app: Haraj', description: 'Design & Interaction'},
    { id: 5, name: 'Redesign an app: Pintrest', description: 'Design & Interaction'},
  ];

  return (
    <ScrollableList>
            <ul style={{ listStyle: 'none', padding: 0, marginLeft: '11.5%', fontSize: '25px' }}>
      {items.map(item => (
        <React.Fragment key={item.id}>
          <li style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
            <Link to={`/item/${item.id}`} style={{ textDecoration: 'none', color: 'black', flex: 1, marginBottom: '5%',fontWeight:"600" }}>
              {item.name}
            </Link>
            <div style={{ flexGrow: 1 }}></div>
            <Link to={`/item/${item.id}`} style={{ textDecoration: 'none', color: 'black', marginBottom: '5%', marginRight: '13%', fontWeight:"600" }}>
              {item.description}
            </Link>
          </li>
          <div style={{ width: '87%', height: '1px', backgroundColor: 'grey', position: 'relative', top: '-20px' }}></div>
        </React.Fragment>
      ))}
    </ul>
  </ScrollableList>
  );
};

export default RecentWorkList;
