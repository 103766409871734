import React from 'react';
import '../App.css';
import Footer from '../components/Footer';
import NavContent from '../components/NavContent';
import RecentWorkList from '../components/RecentWorkList';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
    const navigate = useNavigate();

    const handleClickToAbout = () => {
        navigate('/About');
    }
    const handleClickToWork = () => {
        navigate('/Work');
      }
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflowY: 'auto' }}>

            {/* Nav Content */}
            <NavContent />

            {/* Main Content */}
            <div style={{ position: 'relative', width: '100%', maxWidth: '1177px', margin: '0 auto' }}>
                <img src={require('../imges/r8qmfunngdma1.jpg')} style={{ width: '100%', height: 'auto', maxHeight: "650px", boxShadow: '0px 2px 2px rgba(0,0,0,0.2), -2px -3px 4px rgba(0,0,0,0.2), 2px 4px 8px rgba(0,0,0,0.2), 4px 8px 16px rgba(0,0,0,0.2)' }} />
                <div style={{ position: 'absolute', top: '36%', left: '50%', transform: 'translate(-50%, -50%)', color: 'black', textShadow: '0 1px 3px rgba(0, 0, 0, 0.8)' }}>
                    <p style={{ fontSize: '35px', marginLeft: "23%", marginTop: "7%" }}>UI/UX Designer & Developer</p>
                    <p style={{ position: "felx", marginTop: "20%", fontSize: '125px', fontFamily: 'boogy wood', wordSpacing: '3px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Basayl Suliman</p>
                </div>

                {/* Location Section */}
                <div style={{
                    position: 'absolute', top: '66%', left: '0', right: '69%', margin: '0 auto', display: 'flex', justifyContent: 'center', alignItems: 'center',
                    backgroundColor: '#ab8c68', paddingLeft: '20px', borderTopRightRadius: "30px", borderBottomRightRadius: "30px", width: '100%', maxWidth: '341px'
                }}>
                    <div style={{ color: '#FEFBF2' }}>
                        <p style={{ fontSize: '18px' }}>Located in the <br /><span style={{ fontSize: '20px' }}>Kingdom of Saudi Arabia</span></p>

                    </div>
                    <img src={require('../imges/as.png')} style={{ width: '66px', height: '67px', marginLeft: '8%' }} />
                </div>
            </div>


            {/* Statement Section */}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div style={{ fontSize: '48px', fontWeight: 'unset', marginTop: '4%', marginLeft: '11.5%', display: "inline-block" }}>
                    <p style={{ lineHeight: "45px" }}>I will always be happy to add<br />
                        my design and coding skills to<br />
                        the world. <span style={{ color: "#1F4007", display: "inline-block" }}>Available to work.</span></p>
                </div>

                <div style={{ fontSize: '30px', fontWeight: 'unset', marginRight: '12%', marginTop: "2.5%", lineHeight: "28px" }}>
                    <p>Together we will set the <br />new status quo</p>
                    <button onClick={handleClickToAbout} style={{ width: "330px", height: "50px", borderRadius: "40px", fontSize: "25px", marginTop: "1%", marginLeft: '1%', fontWeight: "unset", backgroundColor: "#A4D3CE", border: "1px solid transparent", cursor:"pointer" }}>About me</button>
                </div>
            </div>


            {/* Recent Work */}
            <div>
                <p style={{ fontSize: '35px', marginTop: '5%', marginLeft: '11.5%', fontWeight: "790", }}>Recent Work</p>
                <RecentWorkList/>
                <button onClick={handleClickToWork} style={{ width: "330px", height: "50px", borderRadius: "40px", fontSize: "25px", marginTop: '5%', marginLeft: '40%', fontWeight: "unset", backgroundColor: "#A4D3CE", border: "1px solid transparent", cursor:"pointer"}}>More work</button>
            </div>

            {/* footer */}
            <Footer/>
        </div>
    );
}

export default HomePage;


















