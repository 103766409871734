import HomePage from "./pages/HomePage";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect } from "react";
import Work from "./pages/Work";
import About from "./pages/About";
import Contact from "./pages/Contact";

function App() {
  useEffect(() => {
    document.title = "Basayl Ali";
  }, []);
  return (
 <div>
  <title>Basayl Ali</title>

    <Router>
      <Routes>
      <Route path="/" element={<HomePage/>} />
      <Route path="/Work" element={<Work/>} />
      <Route path="/About" element={<About/>} />
      <Route path="/Contact" element={<Contact/>} />
      {/* <Route path="/item/:id" element={<ItemDetail />} /> */}
</Routes>
</Router>
</div>
  );
}

export default App;
