import LocalTime from './LocalTime';

const Footer = () => {
    return (

        <div style={{ backgroundColor: "#E6DFD5", width: "100%", height: "100%", marginTop: "15%" }}>
            <p style={{ marginLeft: '10%', fontSize: "70px", fontFamily: "unset", fontWeight: '500' }}>Let's work <br />together</p>
            {/* stright_line */}
            <div style={{ width: "80%", height: "4px", marginLeft: "10%", backgroundColor: "black", position: "relative", top: "-60px" }}>
                <div style={{ width: "225px", height: "225px", borderRadius: "50%", backgroundColor: "#AE8B63", border: "2px solid transparent", display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", top: "-130px", left: "78%", transform: "translateX(-50%)" }}>
                    <span style={{ fontSize: "34px", color: "white", fontWeight: "500" }}>Get in touch</span>
                </div>
            </div>
            <div style={{ display: "flex", fontSize: "23px", marginTop: "5%", marginBottom: "1%", lineHeight: "50px" }}>
                <p style={{ marginLeft: '11.5%' }}><strong>Version</strong><br />2024@Edition</p>
                <p style={{ marginLeft: '5%' }}><strong>Local time</strong><br/><span style={{display: 'flex', gap:"5px"}}><LocalTime/>AST</span></p>
                <p style={{ marginLeft: '44%' }}><strong>Socials</strong><br />
                    <a href="https://www.linkedin.com/in/basayl-ali/" target="_blank" rel="noopener noreferrer">
                        <img src={require('../imges/icons8-linkedin-48.png')} alt="LinkedIn" /></a>
                    <a href="https://www.instagram.com/bes27.6/" target="_blank" rel="noopener noreferrer">
                        <img src={require('../imges/icons8-instagram-48.png')} alt="Instagram" /></a>
                    <a href="https://twitter.com/Basayl01" target="_blank" rel="noopener noreferrer">
                        <img src={require('../imges/icons8-circled-x-50.png')} style={{width:"47px", height:"46px"}} alt="X" />
                    </a>
                </p>
            </div>
        </div>

    );
}
export default Footer;