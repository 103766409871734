import { useNavigate } from "react-router-dom";
import Work from "../pages/Work";
import { useLocation } from 'react-router-dom';


const NavContent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isWorkPage = location.pathname === '/Work';
    const isAboutPage = location.pathname === '/About';
    const isContactPage = location.pathname === '/Contact';
    const isHomePage = location.pathname === '/';

    const handleClickToHome = () => {
        navigate('/');
      }
    
    const handleClickToWork = () => {
        navigate('/Work');
      }
    const handleClickToAbout = () => {
        navigate('/About');
    }
    const handleClickToCContact = () => {
        navigate('/Contact');
    }

    return(
    <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', left: "1090px", fontSize: "23px", fontWeight: "normal" }}>
        <p onClick={handleClickToHome} style={{ cursor: "pointer", marginLeft: "11%", color: isHomePage ? '#AE8B63' : 'black', textDecoration: isHomePage? "underline" : "none" }}>@Basayl Ali</p>
        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '20px', marginRight: "11.5%" }}>
            <p onClick={handleClickToWork} style={{ cursor: "pointer", color: isWorkPage ? '#AE8B63' : 'black', textDecoration: isWorkPage? "underline" : "none" }}>Work</p>
            <p onClick={handleClickToAbout} style={{ cursor: "pointer", color: isAboutPage ? '#AE8B63' : 'black', textDecoration: isAboutPage? "underline" : "none" }}>About</p>
            <p onClick={handleClickToCContact} style={{ cursor: "pointer", color: isContactPage ? '#AE8B63' : 'black', textDecoration: isContactPage? "underline" : "none" }}>Contact</p>
        </div>
    </div>
    );
}
export default NavContent;